import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

import { ptzCCTV } from "../../services/internal/cctvAPI";

function CCTVPtz({ selectCctv, result }) {
  function onClickUp() {
    // 위
    ptzCCTV(selectCctv, "up");
  }
  function onClickLeft() {
    // 좌
    ptzCCTV(selectCctv, "left");
  }
  function onClickRight() {
    // 우
    ptzCCTV(selectCctv, "right");
  }
  function onClickDown() {
    // 아래
    ptzCCTV(selectCctv, "down");
  }
  function onClickZoomIn() {
    // 줌인
    ptzCCTV(selectCctv, "zoom-in");
  }
  function onClickZoomOut() {
    // 줌아웃
    ptzCCTV(selectCctv, "zoom-out");
  }

  return (
    <div id="cctv-ptz-component">
      {(Object.keys(selectCctv).length === 0 || !result) && (
        <div className="cctv-ptz-disable" />
      )}
      {/* Pan/Tilt */}
      <div className="pan-tilt-container">
        <div className="title">Pan/Tilt</div>
        <div className="pannel">
          <div className="pan-tilt">
            <div className="button">
              <div className="button-container top">
                <div className="button-item" onClick={onClickUp}>
                  <FontAwesomeIcon icon={faCaretUp} />
                </div>
              </div>
              <div className="button-container left-right">
                <div className="button-item" onClick={onClickLeft}>
                  <FontAwesomeIcon icon={faCaretLeft} />
                </div>
                <div className="button-item" onClick={onClickRight}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </div>
              </div>
              <div className="button-container bottom">
                <div className="button-item" onClick={onClickDown}>
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </div>
              <div className="inner">
                <div className="circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Zoom */}
      <div className="zoom-container">
        <div className="title">Zoom</div>
        <div className="pannel">
          <div className="zoom">
            <div className="button" onClick={onClickZoomIn}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="button" onClick={onClickZoomOut}>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CCTVPtz;
