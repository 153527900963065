const apiAddress = {
  /* 외부 (GET) */
  //법정동코드
  bdongCd: "https://apis.data.go.kr/1741000/StanReginCd/getStanReginCdList",

  /* 내부 */
  regionGet: "/api/region/regions",
  regionSave: "/api/region/save",
  regionDelete: "/api/region/",
  regionEquipGet: "/api/region/rgn-equips",
  regionEquipSave: "/api/region/rgn-equips/save",
  regionEquipDelete: "/api/region/rgn-equips/",
  equipGet: "/api/device/equips",
  equipSave: "/api/device/equips/save",
  cctvGet: "/api/device/cctv",
  cctvSave: "/api/device/cctv/save",
  cctvDelete: "/api/device/cctv/",
  deviceGet: "/api/device/dvcs",
  deviceSave: "/api/device/dvc/save",
  groupGet: "/api/groups/list",
  groupSave: "/api/groups/save",
  groupDelete: "/api/groups/",
  groupEquipGet: "/api/groups/equips",
  groupEquipSave: "/api/groups/equips/save",
  groupEquipDelete: "/api/groups/equips/",
  groupEquipDeviceGet: "/api/groups/equips/devices",
  groupEquipDeviceSave: "/api/groups/equips/devices/save",
  groupEquipDeviceDelete: "/api/groups/equips/devices/",

  broadTextGet: "/api/device/broadcast/contents",
  broadTextSave: "/api/device/broadcast/contents/save",

  getData: "/api/ext/slope",

  /* 기상청 */
  curWeather: "/api/kma/ultraSrtNcst", // 초단기실황
  curWeatherFore: "/api/kma/ultraSrtFcst", // 초단기예보
  forecast: "/api/kma/vilageFcst", // 단기예보
  satellite: "/api/kma/insightSatlit", // 위성
  radar: "/api/kma/rdrImgInfo", // 레이더
  weatherAlert: "/api/kma/weatherWrnInfo", // 기상특보

  /* express */
  cctvVideo: "/expressApi/cctv",
  cctvVideos: "/expressApi/cctvs",
  cctvCheck: "/expressApi/cctv/check",
  cctvPtz: "/expressApi/cctv/ptz",
  cctvQuit: "/expressApi/cctv/quit",

  localVideo: "/localexpressApi/cctv",
  localCctvVideos: "/localexpressApi/cctvs",
  localQuit: "/localexpressApi/cctv/quit",

  /* SMS */
  smsSend: "/api/sms/sendSms",
  smsList: "/api/sms/list",
  smsDetailList: "/api/sms/sendDetail",
  smsReceiver: "/api/cmmn/getCommonCode", // sms 수신자(공통코드)

  /* 로그인 & 회원가입 & 메뉴조회*/
  login: "/api/login",
  checkDuplicate: "/api/memberExist", // 아이디 중복 검사
  authCellNum: "/api/auth/number", // 휴대폰 인증
  sign: "/api/member/register",
  token: "/api/token",
  getMenu: "/api/menu/getUserMenu", // 사용자에 따라 메뉴조회
  getCommonCode: "/api/cmmn/getCommonCode", // 공통코드
  getTargetMenu: "/api/menu/getTargetMenu", // 고객사ID에 따른 메뉴 조회
  getTermCondition: "/api/auth/term", // 이용약관
  saveMenu: "/api/menu/saveMenu", // 메뉴 저장

  /* 사용자 */
  getUser: "/api/member/list",
  saveUser: "/api/member",
  delUser: "/api/member",
  changePwd: "/api/member/change-password",
  resetPwd: "/api/member/reset-password",
  accessId: "/api/member/status",
  findId: "/api/member/findIds",

  /* 전광판 */
  getEdbMsg: "/api/device/edb",
  saveEdbMsg: "/api/device/edb/save",
  getEdbLog: "/api/device/edbLog",
  sendEdb: "/api/device/edb/send-message",
};

export default apiAddress;
