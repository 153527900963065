import { useState, useEffect } from "react";

import CCTVScreens from "../../../components/cctv/CCTVScreens";

function CCTVs() {
  const [cctvs, setCctvs] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const data = JSON.parse(params.get("data"));
    setCctvs(data);
  }, []);

  return (
    <div id="cctv-show-page">
      <div className="header">
        <div>CCTV Viewer</div>
      </div>
      <div className="main">
        {cctvs.map((cctvInf) => (
          <div
            key={cctvInf.eqId}
            className={
              cctvs.length === 1
                ? "cctv-container one"
                : cctvs.length <= 2
                ? "cctv-container two"
                : cctvs.length <= 4
                ? "cctv-container four"
                : cctvs.length <= 6
                ? "cctv-container six"
                : ""
            }
          >
            <CCTVScreens cctv={cctvInf} />
          </div>
        ))}
      </div>
      <div className="footer">
        <div>Copyright2023. Ahjoo Engineering Inc. All rights reserved.</div>
      </div>
    </div>
  );
}

export default CCTVs;
