import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu } from "../../../redux/modules/menu";

import { tokenCheck } from "../../../utils/tokenCheck";
import { latlon } from "../../../constants/latlon";
import {
  getRainMarkers,
  getLevelMarkers,
  getSlopeMarkers,
  getDisMarkers,
  getEdbMarkers,
  getCCTVMarkers,
} from "../../../utils/map/marker";
import MapControlBar from "../../../components/map/MapControlBar";
import MapType from "../../../components/map/MapType";
import MapDvcPrint from "../../../components/map/MapDvcPrint";
import MapLegend from "../../../components/map/MapLegend";
import MarkerInform from "../../../components/map/inform/MarkerInform";

const { kakao } = window;
const PAGE_VALUE = "지도";

function Map() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [mapType, setMapType] = useState("satellite"); // 지도 관련
  const [map, setMap] = useState(null);
  const [mapLevel, setMapLevel] = useState(3);
  const [dvcPrint, setDvcPrint] = useState([
    "rain",
    "level",
    "slope",
    "displacement",
    "broadcast",
    "edboard",
    "cctv",
  ]);
  const [dvcPrintBfr, setDvcPrintBfr] = useState([
    "rain",
    "level",
    "slope",
    "displacement",
    "broadcast",
    "edboard",
    "cctv",
  ]);

  const [selected, setSelected] = useState({});
  const [rainMarker, setRainMarker] = useState([]);
  const [levelMarker, setLevelMarker] = useState([]);
  const [slopeMarker, setSlopeMarker] = useState([]);
  const [disMarker, setDisMarker] = useState([]);
  const [edbMarker, setEdbMarker] = useState([]);
  const [cctvMarker, setCctvMarker] = useState([]);
  const containerRef = useRef(null);

  /* 마커 이벤트 */
  function markerMouseOver(overlay, clickOverlay) {
    if (clickOverlay.getMap() === null) {
      overlay.setMap(map);
    }
  }
  function markerMouseOut(overlay) {
    overlay.setMap(null);
  }
  function markerClick(overlay, clickOverlay) {
    overlay.setMap(null);
    if (clickOverlay.getMap() === null) {
      clickOverlay.setMap(map);
    } else {
      clickOverlay.setMap(null);
    }
  }
  /* 장비 표시(마커&오버레이) 관련 이벤트 */
  function markerOverlayControl(type, markerInformArr) {
    if (!dvcPrint.includes(type) && dvcPrintBfr.includes(type)) {
      // 표시X
      hiddenMarkerOverlay(markerInformArr);
    }
    if (dvcPrint.includes(type) && !dvcPrintBfr.includes(type)) {
      // 표시O
      printMarkerOverlay(markerInformArr);
    }
  }
  function printMarkerOverlay(markerInformArr) {
    // 장비 표시 O
    if (Array.isArray(markerInformArr) && markerInformArr.length > 0) {
      markerInformArr.forEach((item) => {
        item.marker.setMap(map);
      });
    }
  }
  function hiddenMarkerOverlay(markerInformArr) {
    // 장비 표시 X
    if (Array.isArray(markerInformArr) && markerInformArr.length > 0) {
      markerInformArr.forEach((item) => {
        item.marker.setMap(null);
        item.overlay.setMap(null);
        item.clickOverlay.setMap(null);
      });
    }
  }
  /* 마커 클릭 */
  function onClickMarker(markerInf) {
    setSelected(markerInf);
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    /* 지도 */
    const container = document.getElementById("map-kakao-map");
    const options = {
      center: new kakao.maps.LatLng(latlon.lat, latlon.lon),
      level: 3,
    };
    const defaultMap = new kakao.maps.Map(container, options);

    kakao.maps.event.addListener(defaultMap, "zoom_changed", function () {
      setMapLevel(defaultMap.getLevel());
    });

    defaultMap.setMinLevel(1);
    defaultMap.setMaxLevel(13);
    defaultMap.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);

    setMap(defaultMap);
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);
  useEffect(() => {
    /* 지도 확대/축소 레벨 변경 */
    if (map !== null) {
      map.setLevel(mapLevel, { animate: true });
    }
  }, [mapLevel]);
  useEffect(() => {
    /* 지도 위성/일반 변경 */
    if (map !== null) {
      switch (mapType) {
        case "satellite":
          map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
          break;
        case "normal":
          map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
          break;
        default:
          break;
      }
    }
  }, [mapType]);
  useEffect(() => {
    /* 지도 변경 -> 마커 생성 */
    if (map !== null) {
      // 강우계
      getRainMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setRainMarker(response);
      });
      // 수위계
      getLevelMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setLevelMarker(response);
      });
      // 경사계
      getSlopeMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setSlopeMarker(response);
      });
      // 변위계
      getDisMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setDisMarker(response);
      });
      // 전광판
      getEdbMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setEdbMarker(response);
      });
      // CCTV
      getCCTVMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setCctvMarker(response);
      });
    }
  }, [map]);

  /* 1분마다 상태 업데이트*/
  useEffect(() => {
    // 강우계
    if (map !== null) {
      const timer = setTimeout(() => {
        getRainMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(rainMarker) && rainMarker.length > 0) {
            rainMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setRainMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, rainMarker]);
  useEffect(() => {
    // 수위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getLevelMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(levelMarker) && levelMarker.length > 0) {
            levelMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setLevelMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, levelMarker]);
  useEffect(() => {
    // 경사계
    if (map !== null) {
      const timer = setTimeout(() => {
        getSlopeMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(slopeMarker) && slopeMarker.length > 0) {
            slopeMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setSlopeMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, slopeMarker]);
  useEffect(() => {
    // 변위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getDisMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(disMarker) && disMarker.length > 0) {
            disMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setDisMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, disMarker]);
  useEffect(() => {
    // 전광판
    if (map !== null) {
      const timer = setTimeout(() => {
        getEdbMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(edbMarker) && edbMarker.length > 0) {
            edbMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setEdbMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, edbMarker]);
  useEffect(() => {
    // cctv
    if (map !== null) {
      const timer = setTimeout(() => {
        getCCTVMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(cctvMarker) && cctvMarker.length > 0) {
            cctvMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setCctvMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, cctvMarker]);

  useEffect(() => {
    /* 장비/센서에 대한 마커&오버레이의 활성/비활성화 */
    // 강우계
    markerOverlayControl("rain", rainMarker);
    // 수위계
    markerOverlayControl("level", levelMarker);
    // 변위계
    markerOverlayControl("displacement", disMarker);
    // 경사계
    markerOverlayControl("slope", slopeMarker);
    // 전광판
    markerOverlayControl("edboard", edbMarker);
    // CCTV
    markerOverlayControl("cctv", cctvMarker);
  }, [dvcPrint]);
  useEffect(() => {
    // 전광판 메시지 전송 이후
    if (isChange) {
      setIsChange(false);
      getEdbMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        if (Array.isArray(edbMarker) && edbMarker.length > 0) {
          edbMarker.forEach((item) => {
            // 새로운 마커 정보 => 이전 마커 정보와 동일하게
            const target = response.find((item2) => item2.eqId === item.eqId);
            if (target) {
              // 마커 (default: 마커 출력O)
              if (item.marker.getMap() === null) {
                target.marker.setMap(null);
              }
              // 오버레이 (default: 오버레이 출력X)
              if (item.overlay.getMap() !== null) {
                target.overlay.setMap(map);
              }
              // 클릭오버레이 (default: 클릭오버레이 출력X)
              if (item.clickOverlay.getMap() !== null) {
                target.clickOverlay.setMap(map);
              }
            }

            // 이전 마커 정보 setMap(null)
            item.marker.setMap(null);
            item.overlay.setMap(null);
            item.clickOverlay.setMap(null);
          });
        }
        setEdbMarker(response);
      });
    }
  }, [isChange]);

  return (
    <div id="map-page">
      <div id="map-kakao-map" ref={containerRef} />
      {isToken && (
        <>
          {/* 컨트롤 */}
          <MapControlBar mapLevel={mapLevel} setMapLevel={setMapLevel} />

          {/* 위성/일반 */}
          <MapType mapType={mapType} setMapType={setMapType} />

          {/* 장비표시 */}
          <MapDvcPrint
            dvcPrint={dvcPrint}
            setDvcPrint={setDvcPrint}
            setDvcPrintBfr={setDvcPrintBfr}
          />

          {/* 범례 */}
          <MapLegend />

          {
            // 선택된 마커 정보
            Object.keys(selected).length > 0 && (
              <MarkerInform
                selected={selected}
                setSelected={setSelected}
                setIsChange={setIsChange}
              />
            )
          }
        </>
      )}
    </div>
  );
}

export default Map;
