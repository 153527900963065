import { Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Login from "./pages/login/Login";

import AllStatus from "./pages/menu/allstatus/AllStatus";
import Map from "./pages/menu/map/Map";
import SMSTransmission from "./pages/menu/sms/SMSTransmission";
import SMSList from "./pages/menu/sms/SMSList";
import BroadcastSend from "./pages/menu/broadcast/BroadcastSend";
import BroadcastList from "./pages/menu/broadcast/BroadcastList";
import BroadcastText from "./pages/menu/broadcast/BroadcastText";
import EdboardList from "./pages/menu/edboard/EdboardList";
import EdboardMessage from "./pages/menu/edboard/EdboardMessage";
import EdboardSend from "./pages/menu/edboard/EdboardSend";
import CCTV from "./pages/menu/cctv/CCTV";
import CCTVs from "./pages/menu/cctv/CCTVs";
import DataRain from "./pages/menu/data/DataRain";
import DataLevel from "./pages/menu/data/DataLevel";
import DataDisplacement from "./pages/menu/data/DataDisplacement";
import DataSlope from "./pages/menu/data/DataSlope";
import DataDevice from "./pages/menu/data/DataDevice";
import SensorGrouping from "./pages/menu/data/SensorGrouping";
import SettingRegion from "./pages/menu/setting/SettingRegion";
import SettingBroadGroup from "./pages/menu/setting/SettingBroadGroup";
import SettingAlertGroup from "./pages/menu/setting/SettingAlertGroup";
import SettingDevice from "./pages/menu/setting/SettingDevice";
import SettingEdboard from "./pages/menu/setting/SettingEdboard";
import SettingCCTV from "./pages/menu/setting/SettingCCTV";
import SettingUser from "./pages/menu/setting/SettingUser";

function App() {
  return (
    <Routes>
      <Route index path="/" element={<Login />} />
      <Route path="/cctv/viewer" element={<CCTVs />} />
      <Route element={<Layout />}>
        <Route path="/allstatus" element={<AllStatus />} />
        <Route path="/map" element={<Map />} />
        <Route path="/sms" element={<SMSList />} />
        <Route path="/sms/send" element={<SMSTransmission />} />
        <Route path="/broadcast" element={<BroadcastSend />} />
        <Route path="/broadcast/text" element={<BroadcastText />} />
        <Route path="/broadcast/list" element={<BroadcastList />} />
        <Route path="/edboard" element={<EdboardSend />} />
        <Route path="/edboard/msg" element={<EdboardMessage />} />
        <Route path="/edboard/list" element={<EdboardList />} />
        <Route path="/cctv" element={<CCTV />} />
        <Route path="/data" element={<DataRain />} />
        <Route path="/data/level" element={<DataLevel />} />
        <Route path="/data/displacement" element={<DataDisplacement />} />
        <Route path="/data/surface-slope" element={<DataSlope />} />
        <Route path="/data/device" element={<DataDevice />} />
        <Route path="/data/sensor-grouping" element={<SensorGrouping />} />
        <Route path="/setting" element={<SettingRegion />} />
        <Route path="/setting/broad-group" element={<SettingBroadGroup />} />
        <Route path="/setting/alert-group" element={<SettingAlertGroup />} />
        <Route path="/setting/device" element={<SettingDevice />} />
        <Route path="/setting/edboard" element={<SettingEdboard />} />
        <Route path="/setting/cctv" element={<SettingCCTV />} />
        <Route path="/setting/user" element={<SettingUser />} />
      </Route>
    </Routes>
  );
}

export default App;
