// 로딩
const loading = {
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80px",
    height: "80px",
    background: "transparent",
    border: "none",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};
// alert
const alert = {
  content: {
    width: "400px",
    height: "100px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// confirm
const confirm = {
  content: {
    width: "450px",
    height: "120px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// add (등록)
const add = {
  content: {
    width: "450px",
    height: "120px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// edit (수정)
const edit = {
  content: {
    width: "450px",
    height: "120px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// delete (삭제)
const deleteModal = {
  content: {
    width: "450px",
    height: "120px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
/* reg - 아마 안쓰일거 같음 */
const reg = {
  content: {
    width: "450px",
    height: "120px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// 법정동코드 조회
const bdong = {
  content: {
    width: "550px",
    height: "680px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 위도/경도 조회
const latlon = {
  content: {
    width: "900px",
    height: "600px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 회원가입
const sign = {
  content: {
    width: "850px",
    height: "720px",
    padding: "0px",
    background: "#0C112BA6",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 아이디 찾기
const findId = {
  content: {
    width: "500px",
    height: "250px",
    padding: "0px",
    background: "#0C112BA6",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 아이디 찾기 - 정보
const findIdList = {
  content: {
    width: "500px",
    height: "190px",
    padding: "0px",
    background: "#0C112BA6",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 비밀번호 찾기
const findPwd = {
  content: {
    width: "500px",
    height: "300px",
    padding: "0px",
    background: "#0C112BA6",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

// 종합현황 내 센서 마커 클릭(그래프 & 테이블)
const snsrData = {
  content: {
    width: "800px",
    height: "600px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// rtu
const rtu = {
  content: {
    width: "843px",
    height: "600px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// device
const device = {
  content: {
    width: "843px",
    height: "630px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 사용자
const user = {
  content: {
    width: "900px",
    height: "610px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 비밀번호 변경
const pwdEdit = {
  content: {
    width: "600px",
    height: "330px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// 휴대번호 변경
const cellEdit = {
  content: {
    width: "600px",
    height: "255px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};
// 전광판
const edbaord = {
  content: {
    width: "842px",
    height: "610px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 전광판 메시지
const edboardMsg = {
  content: {
    width: "800px",
    height: "400px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// 전광판 내역 정보
const edboardLog = {
  content: {
    width: "800px",
    height: "550px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};
// CCTV
const cctv = {
  content: {
    width: "842px",
    height: "760px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export const MODALSTYLES = {
  loading: loading,
  alert: alert,
  confirm: confirm,
  add: add,
  edit: edit,
  delete: deleteModal,
  reg: reg, // 아마 안쓰일듯
  bdong: bdong,
  latlon: latlon,
  sign: sign,
  findId: findId,
  findIdList: findIdList,
  findPwd: findPwd,
  snsrData: snsrData,
  rtu: rtu,
  device: device,
  edboard: edbaord,
  edboardMsg: edboardMsg,
  edboardLog: edboardLog,
  cctv: cctv,
  user: user,
  pwdEdit: pwdEdit,
  cellEdit: cellEdit,
};
