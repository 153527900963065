import { useState, useEffect } from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

const sample = [
  { type: "normal", value: 22 },
  { type: "abnormal", value: 4 },
];

function ConnState() {
  const [data, setData] = useState([]);
  const [per, setPer] = useState(0);

  useEffect(() => {
    if (Array.isArray(sample) && sample.length !== 0) {
      setData(sample);

      const totalValue =
        sample.reduce((acc, item) => acc + (item.value || 0), 0) || 1;
      if (totalValue === 0) {
        setPer(0);
      } else {
        const normalValue =
          sample.find((item) => item.type === "normal")?.value || 0;
        const percentage = (normalValue / totalValue).toFixed(2) * 100;
        setPer(percentage);
      }
    } else {
      setData([]);
    }
  }, []);

  /* Tooltip 관련 */
  const CustomTooltup = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const type = payload[0].payload.payload.type;
      const value = payload[0].payload.payload.value;
      return (
        <div className="custom-tooltip">
          <div
            className="color"
            style={{
              background:
                type === "normal"
                  ? "#003cff"
                  : type === "abnormal" && "#ff0000",
            }}
          />
          <div className="label">{`${
            type === "normal" ? "정상" : type === "abnormal" && "이상"
          }:`}</div>
          <div className="value">{value}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div id="allstatus-conn-component">
      {Array.isArray(data) && data.length === 0 ? (
        <div className="data-none-container">등록된 센서가 없습니다</div>
      ) : (
        <>
          {/* 통신 상태 그래프 */}
          <div className="conn-graph">
            {/* 통신 상태 퍼센트 */}
            <div className="percent">{`${per}%`}</div>
            <ResponsiveContainer width="95%" height="95%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={45}
                  outerRadius={75}
                  paddingAngle={5}
                  dataKey="value"
                  stroke="none"
                  activeShape={null}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.type === "normal" ? "#003cff" : "#ff0000"}
                      style={{ outline: "none" }}
                    />
                  ))}
                </Pie>
                <Tooltip content={CustomTooltup} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* 통신 상태 리스트 */}
          <div className="conn-list">
            <div className="conn-item">
              <div className="conn-title">전체</div>
              <div className="conn-value total">
                {data.find((item) => item.type === "normal")?.value +
                  data.find((item) => item.type === "abnormal")?.value}
              </div>
            </div>
            <div className="conn-item">
              <div className="conn-title">정상</div>
              <div className="conn-value normal">
                {data.find((item) => item.type === "normal")?.value}
              </div>
            </div>
            <div className="conn-item conn-item-last">
              <div className="conn-title">이상</div>
              <div className="conn-value abnormal">
                {data.find((item) => item.type === "abnormal")?.value}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ConnState;
